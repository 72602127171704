// parent used for listitems
const state = {
  cardId: '',
  createdTime: 0,
  defaultTheme: '',
  folders: [],
  parent: undefined,
  parts: [{ type: '' }],
  place: '',
  style: '',
  theme: '',
  linkLogo: '',
  openBlankLinkLogo: false,
  seo: false,
  favicon: '',
  title: '',
  tagGaLabel: '',
  tagGaChecked: true,
  tokenId: 0,
  type: 'card',
  updateTime: 0,
  fetching: false,
  error: false,
  saving: false,
  success: false,
  deleting: false,
  deleted: false,
  copied: false,
  redirection_ssr_event: ''
}
export default state
