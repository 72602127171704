import PropTypes from 'prop-types'
import { useState } from 'react'
import { BasicButton, ButtonWithDropdown, DeleteModal, ResetModal } from 'components'
import { useIntl } from 'react-intl'
import { rteCardId, rteCardEditId } from 'utils/routes'

import { connectStore } from 'redux-box'
import { storeModule as CardModule } from 'store/card'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'
import { copy } from 'react-icons-kit/feather/copy'
import { folder } from 'react-icons-kit/feather/folder'

import { StyledCardFooter } from './styles'
import messages from './messages'
import FolderModal from './FolderModal'
import {storeModule as SessionModule} from "../../store/manageUsers";
import {checkActionsAccess} from "../../services/utils";

/* 
  buttons in the footer (2)
  ... expand view (not visible in single page view)
  ... edit with a button attached to a dropdown with options
   .. options are 'edit', 'delete', 'duplicate', 'add to folder'
      . edit links to edit page
      . delete in landing pages reset page values
      . delete in pages deletes the page
      . duplicates opens a new tab with duplicated card (temp)
      . add to folder opens a modal window
  ...
  when fetching database, buttons should be disabled and set as loading
*/
const CardDisplayFooter = ({ appId, card, cardId, tokenId, singleview, tagId, session }) => {
  const [deletePage, setDeletePage] = useState(false)
  const [resetPage, setResetPage] = useState(false)
  const [open, setOpen] = useState(false)

  const intl = useIntl()

  // tokenId 'c' is the old contact page
  const handleOnDelete = () => {
    if (tokenId === 0 || tokenId === 'c') {
      setDeletePage(true)
    } else {
      setResetPage(true)
    }
  }

  const handleOnDuplicate = () => {
    card.duplicateCard(cardId, appId)
  }

  const actions = [];
  if (session?.profile?.email && session.profile.email !== '' && checkActionsAccess(appId, session.profile, 'pages', 'delete')) {
    actions.push({
      label: intl.formatMessage(messages.delete),
      onClick: handleOnDelete,
      icon: <Icon icon={trash} />,
    })
  }
  if (session?.profile?.email && session.profile.email !== '' && checkActionsAccess(appId, session.profile, 'pages', 'duplicate')) {
    actions.push({
      label: intl.formatMessage(messages.duplicate),
      onClick: handleOnDuplicate,
      icon: <Icon icon={copy} />,
    })
  }

  if (session?.profile?.email && session.profile.email !== '' && checkActionsAccess(appId, session.profile, 'pages', 'folders')) {
    actions.push({
      label: intl.formatMessage(messages.folders),
      onClick: () => setOpen(!open),
      icon: <Icon icon={folder} />,
    })
  }

  return (
    <>
      <StyledCardFooter>
        {!singleview ? (
          <BasicButton
            label={intl.formatMessage(messages.view)}
            isLink
            href={rteCardId(appId, cardId)}
            color="blue"
            radius={8}
          />
        ) : (
          <div></div>
        )}

				{session?.profile?.email && session.profile.email !== '' &&
				  checkActionsAccess(appId, session.profile, 'pages', 'edit') && (
				    <ButtonWithDropdown
				      label={intl.formatMessage(messages.edit)}
				      href={rteCardEditId(appId, cardId)}
				      loading={false}
				      actions={actions}
				      appId={appId}
				    />
				  )
				}

      </StyledCardFooter>

      {deletePage && (
        <DeleteModal
          appId={appId}
          cardId={cardId}
          open={deletePage}
          toggle={() => setDeletePage(!deletePage)}
          tagId={tagId}
        />
      )}

      {resetPage && (
        <ResetModal appId={appId} cardId={cardId} open={resetPage} toggle={() => setResetPage(!resetPage)} />
      )}

      {open && <FolderModal open={open} toggle={() => setOpen(!open)} cardId={cardId} />}
    </>
  )
}

CardDisplayFooter.propTypes = {
  appId: PropTypes.string,
  card: PropTypes.object,
  cardId: PropTypes.string,
  singleview: PropTypes.bool,
  tokenId: PropTypes.any,
  tagId: PropTypes.string,
  session: PropTypes.object,
}

export default connectStore({ card: CardModule, session: SessionModule })(CardDisplayFooter)
