import React from 'react';

import {
  StyledTabButton,
  StyledTabButtonLabel,
  StyledTabPanelContainer,
  StyledTabsContainer
} from './styles'
import PropTypes from 'prop-types'

const BasicTabPanel = ({ tabs, activeTab, onTabChange }) => {
  
  const handleTabClick = (tab) => {
    if (tab !== activeTab) {
      onTabChange(tab)
    }
  }
  
  return (
    <StyledTabPanelContainer>
      <StyledTabsContainer>
        {tabs.map((tab) => {
          const isActive = activeTab === tab.tab;
          return (
            <StyledTabButton
              $isActive={isActive}
              key={tab.label}
              onClick={!isActive ? () => handleTabClick(tab.tab) : undefined}
            >
              <StyledTabButtonLabel>{tab.label}</StyledTabButtonLabel>
            </StyledTabButton>
          );
        })}
      </StyledTabsContainer>
      <div className="tab-content">
        {tabs.map((tab) => {
          if (tab.tab === activeTab) {
            return (
              <div key={tab.tab}>{tab.content}</div>
            );
          }
          return null;
        })}
      </div>
    </StyledTabPanelContainer>
  );
};


BasicTabPanel.propTypes = {
  tabs: PropTypes.array,
}

export default BasicTabPanel;
