import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { BasicButton, PageTitle } from 'components'
import { MainAppContainer } from 'containers'
import { connectStore } from 'redux-box'
import { storeModule as AppModule } from '../../store/app'
import { storeModule as ManageUsersModule } from '../../store/manageUsers'
import { appIsSuspended } from '../../services/utils'

import { Icon } from 'react-icons-kit'
import { star } from 'react-icons-kit/feather/star'
import { externalLink } from 'react-icons-kit/feather'

import VisitCounter from './VisitCounter'
import { StyledCard, StyledIcon, StyledSmallLegend } from './styles'
import messages from './messages'
import { useEffect } from 'react'
import history from '../../services/history'
import { rteAppId } from '../../utils/routes'

const PageData = ({
	app,
	manageUsers,
  match: {
    params: { appId },
  },
}) => {
  const intl = useIntl()

	useEffect(() => {
		if (app.accessSuspended && manageUsers.profile.email !== '' && appIsSuspended(app, manageUsers.profile)) {
			history.push(rteAppId(app.id, 'CONTACT'))
		}
	}, [app.accessSuspended])

  return (
    <>
      <Helmet title={intl.formatMessage(messages.metaTitle)} />

      <MainAppContainer appId={appId}>
        <PageTitle>{intl.formatMessage(messages.statistics)}</PageTitle>

        <StyledSmallLegend>{intl.formatMessage(messages.statistics)}</StyledSmallLegend>

        <StyledCard>
          <p>{intl.formatMessage(messages.info)}</p>

          <p>
            <StyledIcon icon={star} />
            {intl.formatMessage(messages.stayTuned)}
            <StyledIcon icon={star} />
          </p>

          <p style={{ marginTop: '40px' }}>{intl.formatMessage(messages.moreInfo)}</p>

          <BasicButton
            href={'https://analytics.google.com'}
            target="_blank"
            label={intl.formatMessage(messages.analytics)}
            icon={<Icon icon={externalLink} />}
            linkstyle
            small
          />
        </StyledCard>

        <VisitCounter appId={appId} />
      </MainAppContainer>
    </>
  )
}

PageData.propTypes = {
	app: PropTypes.object,
	manageUsers: PropTypes.object,
  match: PropTypes.object,
}

export default connectStore({ app: AppModule, manageUsers: ManageUsersModule })(PageData)
