import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.RedirectForm'

export default defineMessages({
  info: { id: `${scope}.info` },
  notes: { id: `${scope}.notes` },
  notesHelp: { id: `${scope}.notesHelp` },
  url: { id: `${scope}.url` },
  urlError: { id: `${scope}.urlError` },
  urlHelp: { id: `${scope}.urlHelp` },
  redirection_ssr_event: { id: `${scope}.redirection_ssr_event` },
  redirection_ssr_eventHelp: { id: `${scope}.redirection_ssr_eventHelp` },
})
