import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { addBrToText } from 'utils'
import styled from 'styled-components'

import { Icon } from 'react-icons-kit'
import { send } from 'react-icons-kit/feather/send'
import { messageCircle } from 'react-icons-kit/feather/messageCircle'

import { ScheduleTooltip } from '../components/Tooltips'
import messages from './messages'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledLabel = styled.label`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
`

const StyledNotes = styled.div`
  word-break: break-word;
  font-size: 12px;
  color: #6c757d;
  font-style: italic;
  display: flex;
  flex-direction: row;

  i {
    display: flex !important;
    margin-right: 5px;
  }
`

const Redirect = ({ cardId, part, partIndex }) => {
  const intl = useIntl()

	const getLabel = (type) => {
		switch (type) {
			case 'RedirectOrdered':
				return intl.formatMessage(messages.redirectOrdered);
			case 'Random':
				return intl.formatMessage(messages.random);
			default:
				return intl.formatMessage(messages.redirect);
		}
	}

  return (
    <StyledWrapper key={`${cardId}-redirect-${partIndex}`}>
      <div>
        <StyledLabel>
          <Icon icon={send} style={{ marginRight: '10px' }} />
          <span>{getLabel(part.type)}</span>
        </StyledLabel>

        <ul style={{ wordBreak: 'break-all' }}>
          {part.urls &&
            part.urls.map((url, i) => (
              <li key={`${cardId}-url-${partIndex}-${i}`} style={{ overflowWrap: 'anywhere' }}>
                {url}
              </li>
            ))}
          {part.url && <li style={{ overflowWrap: 'anywhere' }}>{part.url}</li>}
        </ul>

        {part.notes && (
          <StyledNotes>
            <Icon icon={messageCircle} />
            <span>{addBrToText(part.notes, 'notes')}</span>
          </StyledNotes>
        )}
      </div>

      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Redirect.propTypes = {
  cardId: PropTypes.string,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default Redirect
