import objectPath from "object-path";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { CardBody } from "reactstrap";
import { moveElementInArray, scrollToError } from "utils";
import { BasicButton, BasicForm, CardEditActions, CardEditHeader, FbsContent, PartTypeForm } from "components";
import history from "services/history";
import { rteCardEditId } from "utils/routes";
import { Prompt } from 'react-router-dom';

import { connectStore } from "redux-box";
import { storeModule as ModuleModules } from "store/modules";

import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/feather/plus";

import EditFormParts from "./EditFormParts";
import { emptyFile, getImageSizePromise, resizeImagePart } from "./helpers";
import { UpDownButtons } from "./components";
import messages from "./messages";
import { StyledAddButton, StyledCard, StyledFieldset, StyledInnerCard, StyledLegend } from "./styles";
import FlapPreview from "./components/FlapPreview";
import { cardDirectLink } from "../../utils/generator";


class CardEditForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      appId: props.appId,
      cardId: props.currentCard.cardId,
      createdTime: props.currentCard.createdTime,
      disabled: false,
      folders: props.currentCard.folders || [],
      parts: props.currentCard.parts,
      place: props.currentCard.place || '',
      title: props.currentCard.title,
      tagGaChecked: props.currentCard.tagGaChecked !== undefined ? props.currentCard.tagGaChecked : true,
      tagGaLabel: props.currentCard.tagGaLabel,
      theme: props.currentCard.theme || '',
      linkLogo: props.currentCard.linkLogo || '',
      openBlankLinkLogo: props.currentCard.openBlankLinkLogo,
      seo: props.seo || false,
      favicon: props.currentCard.favicon,
      tokenId: props.currentCard.tokenId,
      updateTime: props.currentCard.updateTime,
      linkPreview: this.generateLinkPreview(props),
      isFormModified: false
    }

    // so the "this" in the functions work:
    this.handleChange = this.handleChange.bind(this)
    this.handleLinkPreview = this.handleLinkPreview.bind(this)
    this.handleAllPartsOverride = this.handleAllPartsOverride.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleVideoChange = this.handleVideoChange.bind(this)
    this.handlePictureChange = this.handlePictureChange.bind(this)
    this.handlePartCheckboxChange = this.handlePartCheckboxChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleAddBlock = this.handleAddBlock.bind(this)
    this.handleRemoveBlock = this.handleRemoveBlock.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleMoveBlock = this.handleMoveBlock.bind(this)
    this.handleResetBlock = this.handleResetBlock.bind(this)
    this.handlePartTypeChange = this.handlePartTypeChange.bind(this)
    this.overridePart = this.overridePart.bind(this)
    this.toggleDisable = this.toggleDisable.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleChangeFolders = this.handleChangeFolders.bind(this);

    this.boundHandleBeforeUnload = this.handleBeforeUnload.bind(this);
    window.addEventListener('beforeunload', this.boundHandleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    if (this.state.isFormModified) {
      const confirmationMessage = 'Êtes-vous certain de vouloir quitter la page sans la sauvegarder?';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };

  resetFormChanges = () => {
    this.setState({ isFormModified: false });
  };

  handleFormChange(isModified) {
    this.setState({ isFormModified: isModified });
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.boundHandleBeforeUnload);
    this.resetFormChanges();
  }

  toggleDisable() {
    this.setState({ disabled: !this.state.disabled })
  }

  handleChange(e) {
    if(e.target.id === 'tagGaChecked') {
      this.setState({ [e.target.id]: e.target.checked })
    } else {
      this.setState({ [e.target.id]: e.target.value })
    }
  }

  handleChangeFolders(folders) {
    const folderIds = folders.map(folder => folder.id);
    this.setState({ folders: folderIds });
  }

  generateLinkPreview(props) {
    return cardDirectLink(props.appId, props.currentCard.tokenId, props.currentCard.cardId)
  }

  handleLinkPreview() {
    this.setState({ linkPreview: this.generateLinkPreview(this.props) });
  }

  handleAllPartsOverride(e) {
    try {
      this.setState({ parts: eval(e.target.value) }) // eslint-disable-line no-eval
    } catch (e) {
      return
    }
  }

  overridePart(newPart, partIndex) {
    const parts = this.state.parts.slice()
    parts[partIndex] = newPart
    this.setState({ parts })
  }

  handleTextChange(e, partIndex) {
    const currentParts = this.state.parts.slice()
    objectPath.set(currentParts, `${partIndex}.${e.target.id}`, e.target.value)
    this.setState({ parts: currentParts })
  }

  handlePartTypeChange(e, partIndex) {
    const partCopy = [...this.state.parts]

    // default values for random
    if (e.target.value === 'Random' || e.target.value === 'RedirectOrdered') {
      partCopy[partIndex] = { type: e.target.value, urls: ['', ''] }
    } else {
      partCopy[partIndex] = { type: e.target.value }
    }

    this.setState({ parts: partCopy })
  }

  handlePartCheckboxChange(e, partIndex) {
    const currentParts = this.state.parts.slice()
    objectPath.set(currentParts, `${partIndex}.${e.target.id}`, e.target.checked)
    this.setState({ parts: currentParts })
  }

  handleCheckboxChange(e) {
    this.setState({ [e.target.id]:  e.target.checked })
  }

  handleFileChange(e, partIndex) {
    const currentParts = this.state.parts.slice()
    objectPath.set(currentParts, `${partIndex}.${e.target.id}`, e.target.files[0])
    this.setState({ parts: currentParts })
  }

  handleVideoChange(file, partIndex, toDelete) {
    const currentParts = this.state.parts.slice()
    if (toDelete) {
      if (currentParts[partIndex].legend) {
        currentParts[partIndex] = {
          type: 'Video',
          legend: currentParts[partIndex].legend,
          ...emptyFile,
        }
      } else {
        currentParts[partIndex] = { type: 'Video', ...emptyFile }
      }
    } else {
      currentParts[partIndex].file = file
    }
    this.setState({ parts: currentParts })
  }

  handlePictureChange(file, partIndex) {
    const self = this
    const currentParts = self.state.parts.slice()
    currentParts[partIndex].file = file

    if (!file) {
      currentParts[partIndex].url = null
    }

    self.setState({ parts: currentParts })
  }

  handleAddBlock(partIndex, e) {
    if (e) {
      e.preventDefault()
    }
    const currentParts = this.state.parts
    currentParts.splice(partIndex + 1, 0, { type: '' })
    this.setState({ parts: currentParts })
    this.handleFormChange(true);
  }

  handleResetBlock(partIndex) {
    const range = { target: { id: 'options.display.range', value: [{}, {}] } }
    this.handleTextChange(range, partIndex)

    const timezone = {
      target: { id: 'options.display.differentTimezones', value: false },
    }
    this.handleTextChange(timezone, partIndex)

    this.handleRemoveBlock(partIndex)
    this.handleAddBlock(partIndex)
  }

  handleRemoveBlock(partIndex) {
    const currentParts = this.state.parts
    currentParts.splice(partIndex, 1)
    this.setState({ parts: currentParts })
  }

  handleMoveBlock(index, move) {
    const { parts } = this.state
    switch (move) {
      case 'top': {
        const newPositionChange = parts.slice(index).length - parts.length
        const newPartsOrder = moveElementInArray(parts, parts[index], newPositionChange)
        this.setState({ parts: newPartsOrder })
        break
      }
      case 'up': {
        const newPartsOrder = moveElementInArray(parts, parts[index], -1)
        this.setState({ parts: newPartsOrder })
        break
      }
      case 'down': {
        const newPartsOrder = moveElementInArray(parts, parts[index], 1)
        this.setState({ parts: newPartsOrder })
        break
      }
      case 'bottom': {
        const newPositionChange = parts.slice(index).length - 1
        const newPartsOrder = moveElementInArray(parts, parts[index], newPositionChange)
        this.setState({ parts: newPartsOrder })
        break
      }
      default:
        return false
    }
    return false
  }

  handleSubmit(e, errors, values) {
    this.handleLinkPreview()
    e.preventDefault()

    if (errors.length) {
      scrollToError()
      return false
    }

    const { title, tagGaChecked, tagGaLabel } = this.state

    // find picture parts in parts, and compress each of them
    const currentParts = this.state.parts
    const promessesArray = []

    // eslint-disable-next-line array-callback-return
    currentParts.forEach((part) => {
      switch (part.type) {
        case 'Picture':
          if (part.isCompressed && part.file) {
            promessesArray.push(resizeImagePart(part, 1920, 1920))
          } else if (!part.isCompressed && part.file && part.file.type !== 'image/gif') {
            part.isCompressed = false
            promessesArray.push(resizeImagePart(part, false, false))
          } else if (part.file) {
            promessesArray.push(getImageSizePromise(part))
          } else {
            promessesArray.push(
              new Promise((resolve) => {
                resolve(part)
              })
            )
          }
          break
        case 'Button-Half':
          if (part.isCompressed && part.file) {
            promessesArray.push(resizeImagePart(part, 600, 600))
          } else if (!part.isCompressed && part.file && part.file.type !== 'image/gif') {
            part.isCompressed = false
            promessesArray.push(resizeImagePart(part, false, false))
          } else if (part.file) {
            promessesArray.push(getImageSizePromise(part))
          } else {
            promessesArray.push(
              new Promise((resolve) => {
                resolve(part)
              })
            )
          }
          break
        default:
          promessesArray.push(
            new Promise((resolve) => {
              resolve(part)
            })
          )
          break
      }
    })

    Promise.all(promessesArray).then((newParts) => {

      const content = {
        ...this.props.currentCard,
        parts: newParts,
        title: title,
        tagGaLabel: tagGaLabel,
        tagGaChecked: tagGaChecked,
        place: this.state.place,
        folders: this.state.folders,
        theme: this.state.theme,
        linkLogo: this.state.linkLogo,
        favicon: values.favicon instanceof File ? values.favicon : this.state.favicon,
        openBlankLinkLogo: this.state.openBlankLinkLogo,
        seo: this.state.seo,
        updateTime: Date.now().toString(),
      }

      this.setState({ parts: newParts, updateTime: Date.now().toString() })
      this.props.onUpdateCard(content)
    })

    if (this.props.isNew) {
      history.push(rteCardEditId(this.props.appId, this.props.currentCard.cardId))
    }
    this.resetFormChanges();
    return true
  }

  handleToggleModal() {
    this.setState(prevState => ({ openDelete: !prevState.openDelete }));
  }

  render() {
    const { title, place, parts, disabled, linkPreview, tagGaLabel, tagGaChecked } = this.state
    const { app, currentCard, appId, fetching, isNew, intl } = this.props

    return (
      <>
        <Prompt
          when={this.state.isFormModified}
          message="Êtes-vous certain de vouloir quitter la page sans la sauvegarder?"
        />
        <BasicForm onSubmit={disabled ? () => {} : this.handleSubmit}>
          <StyledFieldset disabled={fetching}>
            <CardEditActions
              appId={appId}
              cardId={currentCard.cardId}
              isNew={isNew}
              loading={fetching}
              title={intl.formatMessage(isNew ? messages.createCard : messages.editCard)}
              tokenId={currentCard.tokenId}
              onFormChange={this.handleFormChange}
            />

            <StyledCard>
              <CardEditHeader
                app={app}
                card={currentCard}
                cardId={currentCard.cardId}
                handleAllPartsOverride={this.handleAllPartsOverride}
                handleChange={this.handleChange}
                onCheckedChange={this.handleCheckboxChange}
                parts={parts}
                place={place}
                title={title}
                tagGaIsChecked={tagGaChecked}
                tagGaLabel={tagGaLabel}
                tokenId={currentCard.tokenId}
                onAddFolder={this.props.onAddFolder}
                onFormChange={this.handleFormChange}
                handleChangeFolders={this.handleChangeFolders}
              />

              <StyledLegend>{intl.formatMessage(messages.content)}</StyledLegend>

              {parts &&
                parts.map((current, i) => (
                  <Fragment key={`part-${i}`}>
                    <StyledInnerCard>
                      <CardBody style={{ position: 'relative' }}>
                        <UpDownButtons
                          index={i}
                          handleMoveBlock={this.handleMoveBlock}
                          handleRemoveBlock={this.handleRemoveBlock}
                          handleResetBlock={this.handleResetBlock}
                          onFormChange={this.handleFormChange}
                          parts={parts}
                          locked={current.locked}
                        />

                        {current.locked && current.type === 'fbs-content' ? (
                          <FbsContent appId={appId} partIndex={i} part={current} />
                        ) : (
                          <>
                            <PartTypeForm
                              app={app}
                              appId={appId}
                              onChange={this.handlePartTypeChange}
                              part={current}
                              partIndex={i}
                              onFormChange={this.handleFormChange}
                            />

                            <EditFormParts
                              app={app}
                              onChange={this.handleTextChange}
                              onCheckedChange={this.handlePartCheckboxChange}
                              onPictureChange={this.handlePictureChange}
                              onVideoChange={this.handleVideoChange}
                              part={current}
                              partIndex={i}
                              toggleDisable={this.toggleDisable}
                              toggleModal={(e) => this.handleToggleModal(e)}
                              tokenId={currentCard.tokenId}
                              onFormChange={this.handleFormChange}
                            />
                          </>
                        )}
                      </CardBody>
                    </StyledInnerCard>

                    <StyledAddButton>
                      <BasicButton
                        medium
                        color="green"
                        onClick={(e) => this.handleAddBlock(i, e)}
                        icon={<Icon icon={plus} />}
                        label={<FormattedMessage {...messages.addPart} />}
                      />
                    </StyledAddButton>
                  </Fragment>
                ))}
            </StyledCard>

            <CardEditActions
              appId={appId}
              cardId={currentCard.cardId}
              isNew={isNew}
              loading={fetching}
              tokenId={currentCard.tokenId}
              onFormChange={this.handleFormChange}
            />
          </StyledFieldset>
        </BasicForm>
        {!isNew && <FlapPreview link={linkPreview} />}
      </>
    )
  }
}

CardEditForm.defaultProps = {
  onAddFolder: () => {},
  onUpdateCard: () => {},
  onDeleteCard: () => {},
}

CardEditForm.propTypes = {
  app: PropTypes.object,
  appId: PropTypes.any,
  currentCard: PropTypes.object,
  fetching: PropTypes.bool,
  onAddFolder: PropTypes.func,
  onDeleteCard: PropTypes.func,
  onUpdateCard: PropTypes.func,
  modules: PropTypes.object,
}

export default connectStore({ modules: ModuleModules })(injectIntl(CardEditForm))
