import { getColor } from 'styles/functions'
import styled, { css } from 'styled-components'
import { Card } from 'reactstrap'

export const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 771px;
  min-width: 500px;
  flex: 1;

  @media (max-width: 1215px) {
    max-width: 100%;
    min-width: 100%;
  }
`

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
    color: ${({ errorColor }) => (errorColor ? '#ef466f' : '#406cb3')};
  margin-top: 0 !important;
`

export const StyledError = styled.div`
  position: relative;
  color: #721c24;
  background-color: #f8d7da;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  img {
    margin: auto;
  }
`

export const StyledCollapse = styled.div`
  display: flex;
  flex-direction: row;

  i {
    color: ${getColor('blue')};
    cursor: pointer;
  }

  legend {
    cursor: pointer;
  }
`

export const StyledCollapseDiv = styled.div`
  ${({ open }) =>
    !open &&
    css`
      height: 0;
      visibility: hidden;
      margin-top: -10px;

      .form-group {
        margin: 0;
      }
    `}
`

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-of-type) {
    margin-right: 10px;
  }
`

export const StyledInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }

  button {
    padding: 4px 5px 3px 5px;
    margin-left: 5px;
  }
`

export const StyledFieldset = styled.fieldset`
  ${({ disabled }) =>
    disabled &&
    css`
      .search-wrapper {
        background-color: #e9ecef;
        pointer-events: none;
        cursor: default;
      }
    `}
`
