/*
 * PartRandomReset Messages
 *
 * This contains all the text for the PartRandomReset component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.components.PartRandomReset'

export default defineMessages({
  description: {
    id: `${scope}.description`,
  },
  typeLabel: {
    id: `${scope}.typeLabel`,
  },
  typeLabelHelp: {
    id: `${scope}.typeLabelHelp`,
  },
  redirection_ssr_event: { id: `${scope}.redirection_ssr_event` },
  redirection_ssr_eventHelp: { id: `${scope}.redirection_ssr_eventHelp` },
})
