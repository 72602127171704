import styled from 'styled-components'
import { getColor } from 'styles/functions'

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  color: ${({ color }) => (color ? getColor(color) : '#406cb3')};
  font-weight: 600 !important;
  font-size: 14px !important;
`

export const StyledActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.position === 'top' ? 'space-between' : 'flex-end')};
  align-items: center;

  div {
    display: flex;
    flex-direction: row;

    button:last-of-type {
      margin-left: 10px;
    }
  }
`

export const WrapperForBasicImageCrop = styled.div `
  display: flex;
    gap: 30px;
`

export const StyledColorPicker = styled.div`
  margin: 10px 0;

  label {
    font-size: 14px;
  }

  .w-color-swatch {
    justify-content: flex-start;

    &.w-color-circle > div {
      height: 24px !important;
      width: 24px !important;
    }
  }

  .w-color-block {
    width: 100% !important;
  }

  .w-color-editable-input {
    display: none !important;
  }
`