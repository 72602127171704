import PropTypes from 'prop-types'
import { BasicInput } from 'components'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'
import { useIntl } from 'react-intl'
import messages from './messages'

const StyleForm = ({ part, partIndex, onChange, onFormChange }) => {
  const intl = useIntl();

  return (
    <>
      <BasicInput
        name="redirection_ssr_event"
        partIndex={partIndex}
        label={intl.formatMessage(messages.redirection_ssr_event)}
        helpMessage={intl.formatMessage(messages.redirection_ssr_eventHelp)}
        value={part.redirection_ssr_event}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
      />
    </>
  )
}

StyleForm.defaultProps = {
  part: {},
  partIndex: 0,
  onChange: () => {},
  onFormChange: () => {}
}

StyleForm.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func
}

export default connectStore({ modules: ModuleModules })(StyleForm)
