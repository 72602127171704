import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.RandomRedirectStoryForm'

export default defineMessages({
  notes: { id: `${scope}.notes` },
  notesHelp: { id: `${scope}.notesHelp` },
  info: { id: `${scope}.info` },
  tutorial: { id: `${scope}.tutorial` },
  infoModule: { id: `${scope}.infoModule` },
  create: { id: `${scope}.create` },
  stories: { id: `${scope}.stories` },
  edit: { id: `${scope}.edit` },
  select: { id: `${scope}.select` },
  error: { id: `${scope}.error` },
  deletedRandomStory: { id: `${scope}.deletedRandomStory` },
  redirection_ssr_event: { id: `${scope}.redirection_ssr_event` },
  redirection_ssr_eventHelp: { id: `${scope}.redirection_ssr_eventHelp` },
})
