import PropTypes from 'prop-types'
import { ListGroupItem } from 'reactstrap'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { mail } from 'react-icons-kit/feather/mail'
import { Icon } from 'react-icons-kit'
import { alertTriangle } from 'react-icons-kit/feather/alertTriangle'
import { basic_chronometer } from 'react-icons-kit/linea/basic_chronometer'
import { useState, useEffect } from 'react'

const StyledListGroupItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  ${({ noselect }) => noselect === 'true' && 'pointer-events: none;'}
  .disabledItemIcon {
    color: orange;
  }
  .suspendedItemIcon {
      color: #ef466f;
  }
`

const StyledIcon = styled.img`
  border-radius: 50%;
  width: 3em;
  height: 3em;
  margin-right: 10px;
  object-fit: cover;
`

const StyledName = styled.span`
  color: black;
  max-width: 40ch;
  min-width: 4ch;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledDisplay = styled.span`
  color: black;
  display: flex;
  align-items: center;
  max-width: 40ch;
  min-width: 4ch;
  overflow: hidden;
  text-overflow: ellipsis;
  i {
    display: flex !important;
    margin-right: 10px;
  }
`

const StyledDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = ({ noSelect, displayItem, item, link, active, tinny, onClick }) => {
  const handleOnClick = () => onClick && onClick(item)

  const [disabledItem, setDisabledItem] = useState(item.disabled)
  const [suspendedItem, setSuspendedItem] = useState(false)

  useEffect(() => {
    setDisabledItem(item.disabled)
  }, [item.disabled])

	useEffect(() => {
		if (item.accessSuspended && typeof item.accessSuspended.status === 'boolean') {
			setSuspendedItem(item.accessSuspended.status);
		} else {
			setSuspendedItem(false);
		}
	}, [item]);

  const getPeriodSuspended = () => {
    const { suspensionStart = '', suspensionEnd = '' } = item.accessSuspended || {};

    if (suspensionStart && !suspensionEnd) {
      return ` (à partir du ${suspensionStart})`;
    } else if (!suspensionStart && suspensionEnd) {
      return ` (jusqu'au ${suspensionEnd})`;
    } else if (suspensionStart && suspensionEnd) {
      return ` (du ${suspensionStart} au ${suspensionEnd})`;
    }
    return '';
  };

  return (
    <StyledListGroupItem
      tag={link ? Link : 'button'}
      action
      onClick={handleOnClick}
      to={link}
      active={active}
      style={onClick && { cursor: 'pointer' }}
      noselect={String(noSelect)}
    >
      {item.icon && !tinny && <StyledIcon src={item.icon} alt={item.shortName || item.name} />}

      {displayItem && displayItem.length ? (
        <StyledDisplayContainer>
          {displayItem.map((display, i) => (
            <StyledDisplay key={i} >
              {display === 'firstName' && (
                <span>
                  {disabledItem && <span style={{ display: 'inline-block' }}><Icon icon={alertTriangle} className="disabledItemIcon"/></span>}
                  {item.firstName} {item.lastName}
                </span>
              )}

              {display === 'email' && (
                <>
                  <Icon icon={mail} />
                  {item.email}
                </>
              )}

              {display !== 'email' && display !== 'firstName' && item[display]}
            </StyledDisplay>
          ))}

        </StyledDisplayContainer>
      ) : (
        (item.shortName || item.name) && (
          <span>
            {disabledItem && <span style={{ display: 'inline-block' }}><Icon icon={alertTriangle} className="disabledItemIcon"/></span>}
            {suspendedItem && <span style={{ display: 'inline-block' }}><Icon icon={basic_chronometer} className="suspendedItemIcon"/></span>}
            <StyledName> {item.name || item.shortName || ''}</StyledName>
            {suspendedItem && <span style={{ display: 'inline-block', fontSize: 10, marginLeft: 5 }}>{getPeriodSuspended()}</span>}
          </span>
        )
      )
      }
    </StyledListGroupItem>
  )                                                                                                                                                                          
}

Item.propTypes = {
  active: PropTypes.bool,
  displayItem: PropTypes.array,
  item: PropTypes.any,
  link: PropTypes.string,
  onClick: PropTypes.func,
  tinny: PropTypes.any,
  noSelect: PropTypes.bool,
}

export default Item
