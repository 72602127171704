import { defineMessages } from 'react-intl'

const scope = 'app.components.CardDisplay'

export default defineMessages({
  audioElementError: { id: `${scope}.audioElementError` },
  btnSend: { id: `${scope}.btnSend` },
  btnSendEmail: { id: `${scope}.btnSendEmail` },
  clickHereList: { id: `${scope}.clickHereList` },
  copied: { id: `${scope}.copied` },
  deleted: { id: `${scope}.deleted` },
  emptyList: { id: `${scope}.emptyList` },
  input: { id: `${scope}.input` },
  item: { id: `${scope}.item` },
  lblEmailSentAlert: { id: `${scope}.lblEmailSentAlert` },
  list: { id: `${scope}.list` },
  forListitem: { id: `${scope}.forListitem` },
  listitemImage: { id: `${scope}.listitemImage` },
  loading: { id: `${scope}.loading` },
  notFound: { id: `${scope}.notFound` },
  resetRedirect: { id: `${scope}.resetRedirect` },
  resetRedirectOrdered: { id: `${scope}.resetRedirectOrdered` },
  trigger: { id: `${scope}.trigger` },

  // listitem display types
  H2: { id: `${scope}.H2` },
  Picture: { id: `${scope}.Picture` },
  Text: { id: `${scope}.Text` },
  Trigger: { id: `${scope}.trigger` },
  'custom.disponibilitees': { id: `${scope}.disponibilitees` },
  'custom.allergiesImg': { id: `${scope}.allergiesImg` },
  'custom.allergiesTxt': { id: `${scope}.allergiesTxt` },
  'custom.backBtnUrl': { id: `${scope}.backBtnUrl` },
  'custom.backBtnText': { id: `${scope}.backBtnText` },
})
