import { defineMessages } from 'react-intl'

const scope = 'app.components.BasicImageCrop'

export default defineMessages({
  title: { id: `${scope}.title` },
  browse: { id: `${scope}.browse` },
  dropMessage: { id: `${scope}.dropMessage` },
  cancel: { id: `${scope}.cancel` },
  confirm: { id: `${scope}.confirm` },
  error: { id: `${scope}.error` },
})
