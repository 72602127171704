import { defineMessages } from 'react-intl'

const scope = 'app.components.PartTypeForm'

export default defineMessages({
  action: { id: `${scope}.action` },
  audio: { id: `${scope}.audio` },
  button: { id: `${scope}.button` },
  Dispatcher: { id: `${scope}.dispatcher` },
  divider: { id: `${scope}.divider` },
  emailMeBtnLabel: { id: `${scope}.emailMeBtnLabel` },
  facebookButton: { id: `${scope}.facebookButton` },
  Framed: { id: `${scope}.framed` },
  giphy: { id: `${scope}.giphy` },
  googleForm: { id: `${scope}.googleForm` },
  googleMaps: { id: `${scope}.googleMaps` },
  halfButton: { id: `${scope}.halfButton` },
  infoLock: { id: `${scope}.infoLock` },
  infoLockCancel: { id: `${scope}.infoLockCancel` },
  infoLockPage: { id: `${scope}.infoLockPage` },
  input: { id: `${scope}.input` },
  languageLink: { id: `${scope}.languageLink` },
  list: { id: `${scope}.list` },
  lock: { id: `${scope}.lock` },
  lockPage: { id: `${scope}.lockPage` },
  lockReset: { id: `${scope}.lockReset` },
  multiplePictureLabel: { id: `${scope}.multiplePictureLabel` },
  picture: { id: `${scope}.picture` },
  pictureUpload: { id: `${scope}.pictureUpload` },
  photobooth: { id: `${scope}.photobooth` },
  product: { id: `${scope}.product` },
  randomRedirect: { id: `${scope}.randomRedirect` },
  randomReset: { id: `${scope}.randomReset` },
  redirect: { id: `${scope}.redirect` },
  sectionAudio: { id: `${scope}.sectionAudio` },
  sectionButton: { id: `${scope}.sectionButton` },
  sectionImage: { id: `${scope}.sectionImage` },
  sectionIntegration: { id: `${scope}.sectionIntegration` },
  sectionLock: { id: `${scope}.sectionLock` },
  sectionModule: { id: `${scope}.sectionModule` },
  sectionPremium: { id: `${scope}.sectionPremium` },
  sectionRedirect: { id: `${scope}.sectionRedirect` },
  sectionText: { id: `${scope}.sectionText` },
  sectionVideo: { id: `${scope}.sectionVideo` },
  sketchFab: { id: `${scope}.sketchFab` },
  soundCloud: { id: `${scope}.soundCloud` },
  subtitle: { id: `${scope}.subtitle` },
  text: { id: `${scope}.text` },
  trigger: { id: `${scope}.trigger` },
  tutorial: { id: `${scope}.tutorial` },
  type: { id: `${scope}.type` },
  typePlaceholder: { id: `${scope}.typePlaceholder` },
  unityButton: { id: `${scope}.unityButton` },
  video: { id: `${scope}.video` },
  vimeo: { id: `${scope}.vimeo` },
  visitCounter: { id: `${scope}.visitCounter` },
  voxpop: { id: `${scope}.voxpop` },
  youtube: { id: `${scope}.youtube` },

  H2: { id: `${scope}.subtitle` },
  LanguageLink: { id: `${scope}.languageLink` },
  Text: { id: `${scope}.text` },
  Separator: { id: `${scope}.divider` },
  Picture: { id: `${scope}.pictureUpload` },
  PictureUrl: { id: `${scope}.picture` },
  Giphy: { id: `${scope}.giphy` },
  SketchFab: { id: `${scope}.sketchFab` },
  Audio: { id: `${scope}.audio` },
  SoundCloud: { id: `${scope}.soundCloud` },
  Video: { id: `${scope}.video` },
  Vimeo: { id: `${scope}.vimeo` },
  VideoYoutube: { id: `${scope}.youtube` },
  Button: { id: `${scope}.button` },
  'Button-Half': { id: `${scope}.halfButton` },
  FacebookShareButton: { id: `${scope}.facebookButton` },
  BtnUnity: { id: `${scope}.unityButton` },
  GoogleMaps: { id: `${scope}.googleMaps` },
  FormGoogle: { id: `${scope}.googleForm` },
  Redirect: { id: `${scope}.redirect` },
	RedirectOrdered: { id: `${scope}.redirectOrdered` },
	RedirectOrderedReset: { id: `${scope}.redirectOrderedReset` },
  Random: { id: `${scope}.randomRedirect` },
  RandomStory: { id: `${scope}.randomStory` },
  RandomStoryReset: { id: `${scope}.randomStoryReset` },
  RandomReset: { id: `${scope}.randomReset` },
  Lock: { id: `${scope}.lock` },
  LockCancel: { id: `${scope}.lockReset` },
  LockPage: { id: `${scope}.lockPage` },
  Photobooth: { id: `${scope}.photobooth` },
  List: { id: `${scope}.list` },
  Trigger: { id: `${scope}.trigger` },
  ListItem: { id: `${scope}.product` },
  Input: { id: `${scope}.input` },
  VisitCounter: { id: `${scope}.visitCounter` },
})
