import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInfo, PartOptions } from "components";

import messages from './messages'
import RandomStorySelect from "./RandomStorySelect";

const RandomRedirectStoryResetForm = ({ onChange, partIndex, part }) => {
  const intl = useIntl();

  const handleOnSelect = (e) => {
    e.preventDefault()
    onChange(e, partIndex)
  }

  return (
    <>
      <PartOptions
        partIndex={partIndex}
        onChange={onChange}
        values={part.options}
      />

      <hr/>

      <RandomStorySelect id={part.id} onSelect={handleOnSelect} partIndex={partIndex} />

      <div style={{ marginTop: '20px' }}>
        <BasicInfo text={intl.formatMessage(messages.infoModule)} />
      </div>
    </>
  )
}

RandomRedirectStoryResetForm.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default RandomRedirectStoryResetForm
