import styled from 'styled-components';

export const StyledTabPanelContainer = styled.div`
  width: 100%;
  min-height: 600px;
  .tab-content {
    margin-top: 25px;
  }
`;

export const StyledTabsContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
`;

export const StyledTabButton = styled.div`
  width: 250px;
  background-color: transparent;
  height: 30px;
  text-align: center;
  border-bottom: ${(props) => props.$isActive ? '2px solid #406cb3' : '1px solid #f0f0f0'};
  color: ${(props) => props.$isActive ? '#406cb3' : 'black'};
  cursor: ${(props) => props.$isActive ? 'normal' : 'pointer'};
`;

export const StyledTabButtonLabel = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  font-family: Nunito;
`;
