const oneCard = (tokenId, cardId, date = 0, theme) => {
  let title = ''
  let parts = [{ type: '' }]
  return {
    cardId,
    createdTime: date,
    parts,
    title,
    tokenId,
    theme,
    updateTime: date,
  }
}

export const createCards = (tokenCardLinks, date, theme) => {
  const list = [];

  Object.values(tokenCardLinks).map((link) => list.push(oneCard(link.tokenId, link.cardId, date, theme)))

  return list
}

export const getTokenCardLinks = (tokenList, date) => {
  const list = {}

  tokenList.map((token, index) => {
    return list[index] = {cardId: token.toString(), tokenId: parseInt(token), update: date}
  })

  return list
}

const getListThemes = (themes) => {
  const list = []

  themes.map((theme) => list.push(theme.value ? theme.value : theme))

  return list
}

const getTokenList = (tokens) => {
  const list = []
  for (let i = 1; i <= tokens; i += 1) {
    list.push(i)
  }
  return list
}

export const getApp = (dbApp, app, date) => {
  const tokens = app.numberTokens || dbApp.numberTokens || dbApp.tokenList.length

  const tokenList = getTokenList(tokens)
  const themes = getListThemes(app.themes)

  return {
      ...dbApp,
      name: app.name,
      favicon: app.favicon || '',
      theme: app.theme,
      themes: themes,
      notes: app.notes,
      lastCardUpdateTime: date,
		  tracking: {
			  ga4code: app.tracking?.ga4code ?? dbApp.tracking?.ga4code ?? '',
			  datastreamcode: app.tracking?.datastreamcode ?? dbApp.tracking?.datastreamcode ?? ''
		  },
      legalContacts: app.legalContacts,
      numberTokens: tokens,
      internalNotes: app.internalNotes || dbApp.internalNotes,
      GDPR: app.GDPR ?? dbApp.GDPR ?? {},
      plan: app.plan,
	    accessSuspended: app.accessSuspended,
      logo: app.logo || '',
	    seo: app.seo || {},
		  redirectService: {
			  limit: app.redirectService?.limit !== undefined
				  ? parseInt(app.redirectService.limit)
				  : (dbApp.redirectService?.limit ?? 0),
			  domains: app.redirectService?.domains ?? dbApp.redirectService?.domains ?? [],
			  rewrites: app.redirectService?.rewrites ?? dbApp.redirectService?.rewrites ?? []
		  },
      tokenList,
  }
}

export const getNewApp = (app, date) => {
  const tokens = app.numberTokens;

  const tokenList = getTokenList(tokens)
  const tokenCardLinks = getTokenCardLinks(tokenList, date)
  const themes = getListThemes(app.themes)

  return {
    id: app.id,
    name: app.name,
    theme: app.theme || '',
    themes: themes || [],
    notes: app.notes || '',
    lastCardUpdateTime: date,
    tracking: {
      ga4code: (app.tracking && app.tracking.ga4code) || '',
      datastreamcode: (app.tracking && app.tracking.datastreamcode) || ''
    },
    legalContacts: app.legalContacts,
    internalNotes: app.internalNotes ? app.internalNotes : '',
    GDPR: app.GDPR,
    foldersOrder: 'desc',
    plan: app.plan,
    cardList: [],
	  accessSuspended: {
		  status: (app.accessSuspended.status ?? false ),
		  suspensionStart: (app.accessSuspended.suspensionStart ?? ''),
		  suspensionEnd: (app.accessSuspended.suspensionEnd ?? ''),
		  urlDestination: (app.accessSuspended.urlDestination ?? ''),
		  notes: (app.accessSuspended.notes ?? ''),
	  },
	  redirectService: {
		  limit: parseInt(app.redirectService?.limit ?? 0),
      domains: app.redirectService?.domains ?? [],
      rewrites: app.redirectService?.rewrites ?? []
	  },
    seo: app.seo || {},
    tokenCardLinks,
    tokenList
  };
}
