import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicCheckbox, BasicInput, BasicRadioButtons, BasicTextArea } from 'components'
import { newEvent, validationsPatterns } from 'utils'

import messages from './messages'

const Params = ({ onChange, onCheckedChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  const handleOnCheckedChange = (e) => {
    if (e.target.checked) {
      onChange(newEvent('timeParamUnit', ''), partIndex)
      onChange(newEvent('timeValue', 1), partIndex)
    }
    onCheckedChange(e, partIndex)
    onFormChange(true);
  }

  return (
    <>
      <BasicCheckbox
        label={intl.formatMessage(messages.show)}
        name="timeParams"
        value={part.timeParams}
        onChange={handleOnCheckedChange}
      />

      {part.timeParams && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BasicInput
            type="number"
            name="timeValue"
            label={intl.formatMessage(messages.time)}
            min={1}
            validation={{ pattern: validationsPatterns.positiveIntegerNoZero }}
            value={part.timeValue || 1}
            onChange={(e) => {
              onChange(e, partIndex);
              onFormChange(true);
            }}
          />

          <div style={{ marginLeft: '10px', marginTop: '10px' }}>
            <BasicRadioButtons
              name="timeParamUnit"
              error={intl.formatMessage(messages.timeError)}
              required={part.timeParams}
              value={part.timeParamUnit}
              onChange={(e) => {
                onChange(newEvent("timeParamUnit", e.target.value), partIndex);
                onFormChange(true);
              }}
              radioButtons={[
                { label: intl.formatMessage(messages.minutes), value: 'minutes' },
                { label: intl.formatMessage(messages.hours), value: 'hours' },
                { label: intl.formatMessage(messages.days), value: 'days' },
              ]}
              inline
            />
          </div>
        </div>
      )}

      <BasicCheckbox
        label={intl.formatMessage(messages.repeat)}
        name="contentParam"
        value={part.contentParam}
        onChange={(e) => {
          onCheckedChange(e, partIndex);
          onFormChange(true);
        }}
      />

	    <BasicInput
		    name="redirection_ssr_event"
		    partIndex={partIndex}
		    label={intl.formatMessage(messages.redirection_ssr_event)}
		    helpMessage={intl.formatMessage(messages.redirection_ssr_eventHelp)}
		    value={part.redirection_ssr_event}
		    inputClassName=""
		    onChange={(e) => {
			    onChange(e, partIndex);
			    onFormChange(true);
		    }}
	    />

      <BasicTextArea
        name="notes"
        partIndex={partIndex}
        label={intl.formatMessage(messages.notes)}
        helpMessage={intl.formatMessage(messages.notesHelp)}
        value={part.notes || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        size="xsmall"
      />
    </>
  )
}

Params.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.any,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Params
