import 'moment/locale/en-ca'
import 'moment/locale/fr-ca'
import localeEn from 'antd/lib/date-picker/locale/en_US'
import localeFr from 'antd/lib/date-picker/locale/fr_FR'
import { DatePicker } from 'antd'

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'

const options = {
  timezone: 'America/Toronto',
  dateAndTimeFormat: {
    en: 'MM/DD/YYYY',
    fr: 'DD/MM/YYYY',
  },
  dateFormat: {
    en: 'MM/DD/YYYY',
    fr: 'DD/MM/YYYY',
  },
  dbDateFormat: 'DD/MM/YYYY',
  values: {
    display: {
      allDay: false,
      range: [{}, {}],
      repeat: 'no',
      rule: 'show',
      differentTimezones: false,
    },
  },
}

const BasicSimpleDatePicker = ({ value, name, placeholder, onChange, allDay }) => {
  const [datepickerValue, setDatepickerValue] = useState(null)

  const intl = useIntl()

  const format =
    !allDay || allDay === 'false' ? options.dateAndTimeFormat[intl.locale] : options.dateFormat[intl.locale]
  
  useEffect(() => {
    if(value) {
      const date = inverseFormatDate(value)
      setDatepickerValue(date)
    }
  }, [value])
  
  const inverseFormatDate = (value) => {
    const splitValue = value.split('/')

    if(intl.locale === 'en') {
      return moment(`${splitValue[1]}/${splitValue[0]}/${splitValue[2]}`, format)
    } else {
      return moment(value, format)
    }
  }
  
  const handleDateChange = (value) => {
    setDatepickerValue(value)
    const dateString = value ? value.format(options.dbDateFormat) : null
    onChange(dateString)
  }
  
  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };
  
  return (
    <div>
      <DatePicker
        value={datepickerValue}
        name={name}
        id={name}
        disabledDate={disabledDate}
        locale={intl.locale === 'fr' ? localeFr : localeEn}
        onChange={handleDateChange}
        format={format}
        size="large"
        defaultValue={datepickerValue}
        placeholder={placeholder}
        style={{
          marginRight: '10px',
          width: '250px',
          borderRadius: '8px',
          padding: '10px',
          borderColor: '#bebebe',
        }}
      />
    </div>
  )
}

BasicSimpleDatePicker.propTypes = {
  allDay: PropTypes.any,
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default BasicSimpleDatePicker
