const state = {
  redirectService: {
    limit: 0,
    domains: [],
    rewrites: []
  },
  numberCustomDomain: 0,
  favicon: '',
  appList: [],
  listDisabledApps: [],
	listSuspendedApps: [],
  getAppInfo: {
    error: false,
    fetching: true,
  },
  createApp: {
    fetching: false,
    error: false,
    success: false,
    badEmail: false,
  },
  apps: {
    fetching: false,
    error: false,
    list: [],
  },
  appInfo: {
    userList: [],
  },
  appListDetails: [],
	accessSuspended: {
		status: false,
		suspensionStart: '',
		suspensionEnd: '',
		urlDestination: '',
		notes: '',
	},
}

export default state
