/*
 * PartRandomReset Messages
 *
 * This contains all the text for the PartRandomReset component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.components.PartRedirectOrderedReset'

export default defineMessages({
  description: { id: `${scope}.description`, },
  typeLabel: { id: `${scope}.typeLabel`, },
  typeLabelHelp: { id: `${scope}.typeLabelHelp`, },
})
