import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { FormText } from 'reactstrap'
import { addBrToText } from 'utils'
import { Img, CardDisplayFooter, CardDisplayHeader } from 'components'
import { withRouter } from 'react-router-dom'

import { Icon } from 'react-icons-kit'
import { send } from 'react-icons-kit/feather/send'
import { moreHorizontal } from 'react-icons-kit/feather/moreHorizontal'
import { chevronUp } from 'react-icons-kit/feather/chevronUp'

import {
  Audio,
  Button,
  FacebookButton,
  Framed,
  Photobooth,
  Picture,
  Lock,
  Dispatcher,
  Redirect,
  Text,
  Videos,
  Subtitle,
  Giphy,
} from './parts'
import { ModuleList, ModuleItem, ModuleTrigger, ModuleInput } from './modules'
import { PartCommentForm } from './components'
import { ScheduleTooltip } from './components/Tooltips'
import { FbsContentInfo, ItemInfo, LockInfo } from './components/Info'
import { ThemeTag, SystemTag, FolderTag } from './components/Tags'
import messages from './messages'
import {
  Separator,
  StyledCard,
  StyledLabel,
  StyledCardBody,
  StyledMoreButton,
  StyledPartWithOptions,
  StyledPartContainer
} from './styles'
import ListHalfButton from './modules/lists/ListHalfButton'
import RamdonStory from "./parts/RamdonStory";
import LanguageLink from "./parts/LanguageLink";

const CardDisplay = ({ currentCard, appId, singleview, tagId, stateCollapse, headersFilters }) => {
  const [open, setOpen] = useState(false)
  const [isCardHuge, setIsCardHuge] = useState(!singleview && currentCard.parts && currentCard.parts.length >= 2)

  const partsCardId = document.getElementById("partsCardId-"+currentCard.cardId)

  const intl = useIntl()

  useEffect(() => {
    setOpen(stateCollapse === "expand")
  }, [stateCollapse])

  const isLock = (part) => part.type === 'Lock' || part.type === 'LockCancel' || part.type === 'LockPage'

  const getFbsContent = () =>
    currentCard.parts && currentCard.parts.find((part) => part && part.type === 'fbs-content')

  const getLocks = () =>
    currentCard.parts &&
    currentCard.parts.filter(part => isLock(part) && (part.type === 'LockPage' || part.type === 'LockCancel'));

  const getGoogleForm = () =>
    currentCard.parts && currentCard.parts.find((part) => part.type === 'FormGoogle')
  const getDispatcher = () =>
    currentCard.parts && currentCard.parts.find((part) => part.type === 'Dispatcher')

  const showExpendBtn = () => {
    if(partsCardId && !isCardHuge){
      const childDiv = partsCardId.children[0]
      if (childDiv && childDiv.offsetHeight > partsCardId.offsetHeight) {
        setIsCardHuge(true)
      }
    }
  }

  if(partsCardId && partsCardId.children[0]){
    showExpendBtn()
  }

  const languageLinkPart = currentCard.parts?.filter(part => part.type === "LanguageLink");

  return (
    <StyledCard
      multiple={!singleview}
      style={{
        maxHeight: !open && !singleview ? '700px' : '100%',
      }}
    >
      {/* header */}
      <CardDisplayHeader {...currentCard} appId={appId} singleview={singleview} folders={currentCard.folders} headersFilters={headersFilters} />

      {/* tags */}
	    <SystemTag type={currentCard.type} />
	    {headersFilters.theme && <ThemeTag theme={currentCard.theme} />}
	    {headersFilters.projects && <FolderTag folders={currentCard.folders} />}

      {/* info */}
      <ItemInfo {...currentCard} appId={appId} wrap={!singleview} />
      {getFbsContent() && <FbsContentInfo part={getFbsContent()} appId={appId} wrap={!singleview} />}

      {/* parts */}
      <StyledCardBody id={"partsCardId-"+currentCard.cardId}>
       {headersFilters.language && languageLinkPart && languageLinkPart.length > 0 && <LanguageLink part={languageLinkPart[0]} partIndex={0} cardId={currentCard.cardId} />}
        {getLocks() && getLocks().length > 0 && (
          <LockInfo parts={getLocks()} appId={appId} wrap={!singleview} types={['LockPage', 'LockCancel']} />
        )}
        {currentCard.parts &&
          // eslint-disable-next-line
          currentCard.parts.map((part, i) => {
            if (part.type) {
              switch (part.type) {
                case 'Button':
                  return <Button key={i} part={part} partIndex={i} cardId={currentCard.cardId} />
                case 'Button-Half':
                  return <ListHalfButton key={i} allParts={currentCard.parts} currentIndex={i} />
                case 'BtnUnity':
                  return (
                    <Button part={part} partIndex={i} cardId={currentCard.cardId}>
                      {part.text}
                    </Button>
                  )
                case 'H2':
                  return <Subtitle key={i} part={part} partIndex={i} cardId={currentCard.cardId} />
                case 'Giphy':
                  return <Giphy key={i} part={part} partIndex={i} cardId={currentCard.cardId} />
                case 'Text':
                  return <Text key={i} part={part} partIndex={i} cardId={currentCard.cardId} />
                case 'Picture':
                  return <Picture key={i} part={part} partIndex={i} cardId={currentCard.cardId} />
                case 'PictureUrl':
                  return (
                    <StyledPartWithOptions
                      key={`${currentCard.cardId}-picture-url-${i}`}
                      style={{ paddingLeft: '0', paddingRight: '0' }}
                    >
                      <div style={{ flex: '1' }}>
                        <Img src={part.url} alt="" />
                        {part.legend && (
                          <FormText
                            color="muted"
                            style={{
                              textAlign: 'center',
                              margin: '5px 1.2rem 1.2rem 1.2rem',
                              lineHeight: '14px',
                            }}
                          >
                            {addBrToText(part.legend, 'part-lgd')}
                          </FormText>
                        )}
                      </div>
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
                case 'VideoYoutube':
                case 'Video':
                case 'Vimeo':
                case 'SoundCloud':
                  return <Videos key={i} part={part} partIndex={i} singleview={singleview} />
                case 'Redirect':
                case 'RedirectOrdered':
                case 'Random':
                  return <Redirect key={i} part={part} partIndex={i} cardId={currentCard.cardId} />
                case 'RandomReset':
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-random-reset-${i}`}>
                      <div>
                        <Icon icon={send} style={{ marginRight: '10px' }} />
                        <StyledLabel>{intl.formatMessage(messages.resetRedirect)}</StyledLabel>
                        <br />
                        <ul>
                          <li style={{ overflowWrap: 'anywhere' }}>{part.tokenToReset}</li>
                        </ul>
                      </div>
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
	              case 'RedirectOrderedReset':
		              return (
			              <StyledPartWithOptions key={`${currentCard.cardId}-redirect-ordered-reset-${i}`}>
				              <div>
					              <Icon icon={send} style={{ marginRight: '10px' }} />
					              <StyledLabel>{intl.formatMessage(messages.resetRedirectOrdered)}</StyledLabel>
					              <br />
					              <ul>
						              <li style={{ overflowWrap: 'anywhere' }}>{part.tokenToReset}</li>
					              </ul>
				              </div>
				              <ScheduleTooltip part={part} />
			              </StyledPartWithOptions>
		              )
                case 'RandomStory':
                  return <RamdonStory key={i} part={part} partIndex={i} cardId={currentCard.cardId} type={'RandomStory'} />
                case 'RandomStoryReset':
                  return <RamdonStory key={i} part={part} partIndex={i} cardId={currentCard.cardId} type={'RandomStoryReset'} />
                case 'CommentForm':
                  return <PartCommentForm key={`${currentCard.cardId}-comment-${i}`} partIndex={i} />
                case 'Audio':
                  return <Audio key={i} part={part} partIndex={i} cardId={currentCard.cardId} />
                case 'FacebookShareButton':
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-fcbutton-${i}`}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <FacebookButton
                          text={part.text}
                          tokenId={currentCard.tokenId}
                          cardId={currentCard.cardId}
                          appId={appId}
                        />
                      </div>
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
                case 'FormGoogle':
                  if (part.src) {
                    return (
                      <StyledPartWithOptions key={`${currentCard.cardId}-form-${i}`}>
                        <iframe
                          title={`google-form-${i}`}
                          src={part.src}
                          width="100%"
                          height={part.height ? part.height : '600px'}
                          frameBorder="0"
                          marginHeight="0"
                          marginWidth="0"
                        >
                          {intl.formatMessage(messages.loading)}
                        </iframe>
                        <ScheduleTooltip part={part} />
                      </StyledPartWithOptions>
                    )
                  }
                  break
                case 'SketchFab':
                  if (part.src) {
                    return (
                      <StyledPartWithOptions key={`${currentCard.cardId}-sketchfab-${i}`}>
                        <iframe
                          title="A 3D model"
                          width={part.options ? '80%' : '100%'}
                          height={part.height || '600px'}
                          src={part.src}
                          frameBorder="0"
                          allow="autoplay; fullscreen; vr"
                          mozallowfullscreen="true"
                          webkitallowfullscreen="true"
                        />
                        <ScheduleTooltip part={part} />
                      </StyledPartWithOptions>
                    )
                  }
                  return (
                    <StyledPartContainer
                      key={`${currentCard.cardId}-sketchfab-${i}`}
                      style={{ textAlign: 'center' }}
                    >
                      <pre style={{ marginBottom: '0' }}>{part.type}</pre>
                    </StyledPartContainer>
                  )

                case 'GoogleMaps':
                  if (part.src) {
                    return (
                      <StyledPartWithOptions key={`${currentCard.cardId}-map-${i}`}>
                        <iframe
                          title="Google Map"
                          width="100%"
                          height={part.height || '600px'}
                          src={part.src}
                          frameBorder="0"
                          allow="autoplay; fullscreen; vr"
                          mozallowfullscreen="true"
                          webkitallowfullscreen="true"
                        />
                        <ScheduleTooltip part={part} />
                      </StyledPartWithOptions>
                    )
                  }
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-map-${i}`}>
                      <div style={{ textAlign: 'center', width: '100%' }}>
                        <pre style={{ marginBottom: '0' }}>{part.type}</pre>
                      </div>
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
                case 'Lock':
                case 'LockCancel':
                case 'LockPage':
                  return (
										<StyledPartWithOptions key={`${currentCard.cardId}-lock-${i}`}>
                      <Lock key={i} appId={appId} part={part} partIndex={i} singleview={singleview} />
                    </StyledPartWithOptions>
                  )
	              case 'Dispatcher':
		              return <div key={i} style={{margin: '20px'}}>
			              <Dispatcher appId={appId} part={part} partIndex={i} singleview={singleview} />
		              </div>
                case 'Trigger':
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-trigger-${i}`}>
                      <ModuleTrigger id={part.id} />
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
                case 'List':
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-list-${i}`}>
                      <ModuleList id={part.id} appId={appId} />
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
                case 'ListItem':
                  return <ModuleItem part={part} id={part.id} key={`${currentCard.cardId}-item-${i}`} />
                case 'Input':
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-input-${i}`}>
                      <ModuleInput {...part} />
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
                case 'Separator':
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-divider-${i}`}>
                      <Separator size={part.length || 'small'} />
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
                case 'fbs-content':
                  break
                case 'Photobooth':
                  return <Photobooth key={i} part={part} partIndex={i} />
                case 'LanguageLink':
                  break;
                case 'Framed':
                  return <Framed key={i} part={part} partIndex={i} />
                default:
                  return (
                    <StyledPartWithOptions key={`${currentCard.cardId}-fbs-${i}`}>
                      <div style={{ textAlign: 'center', width: '100%' }}>
                        <pre style={{ marginBottom: '0' }}>
                          {part.type} {part.style ? part.style.displayType : ''}
                        </pre>
                      </div>
                      <ScheduleTooltip part={part} />
                    </StyledPartWithOptions>
                  )
              }
            }
          })}
      </StyledCardBody>

      {(isCardHuge || getGoogleForm() || getDispatcher) && (
        <StyledMoreButton open={open}>
          <button onClick={() => setOpen(!open)}>
            <Icon icon={!open ? moreHorizontal : chevronUp} />
          </button>
        </StyledMoreButton>
      )}

      {/* footer */}
      <CardDisplayFooter
        appId={appId}
        cardId={currentCard.cardId}
        singleview={singleview}
        tokenId={currentCard.tokenId}
        tagId={tagId}
      />
    </StyledCard>
  )
}

CardDisplay.defaultProps = {
  appId: '',
  currentCard: { parts: [{ type: '', urls: [''] }] },
  singleview: false,
}

CardDisplay.propTypes = {
  appId: PropTypes.string.isRequired,
  currentCard: PropTypes.object,
  singleview: PropTypes.bool,
  tagId: PropTypes.string,
  stateCollapse: PropTypes.string,
	headersFilters: PropTypes.object,
}

export default withRouter(CardDisplay)
