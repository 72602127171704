import { createSagas } from 'redux-box'
import { call, put, take } from 'redux-saga/effects'
import rsFirebase from 'services/rsf'
import { rteAppId, rteRandomStoryId } from '../../utils/routes'

export default createSagas({
  *RANDOM_STORY_GET_LIST({ appId }) {
    try {
      const collection = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/randomStories`)
      const data = {}

      collection.forEach((document) => {
        const doc = document.data()
        data[doc.id] = doc
      })

      yield put({ type: 'RANDOM_STORY_SET_LIST', data })
    } catch (error) {
      yield put({ type: 'RANDOM_STORY_ERROR' })
    }
  },
  *RANDOM_STORY_SAVE({ appId, cardId, randomStory, onSave = () => {} }) {
    try {
      const { id } = randomStory
      const path = `appdata/${appId}/randomStories/${id}`
      const data = {
        id,
        dateUpdated: new Date(Date.now()),
        appId,
        cardId: cardId || '',
        ...randomStory,
        limit: randomStory.randomMode === true ? randomStory.limit : randomStory.urls.length,
      }
      yield put({ type: 'RANDOM_STORY_SAVING', module })
      yield call(rsFirebase.firestore.setDocument, path, data, { merge: true })

      yield put({
        type: 'LOGS_ADD_LOGS_CONTENT',
        appId: appId,
        action: 'save',
        where: rteRandomStoryId(appId, id),
        typeContent: 'randomStory',
        content: id,
      })
      yield take('LOGS_ADD_LOGS_SUCCESS')
      yield put({ type: 'LOGS_ADD_LOGS_RESET_SUCCESS' })

      yield put({ type: 'RANDOM_STORY_ADD_TO_LIST', data })
      yield put({ type: 'RANDOM_STORY_SAVE_SUCCESS', module })
      onSave(id)
    } catch (error) {
      yield put({ type: 'RANDOM_STORY_SAVE_ERROR' })
    }
  },
  *RANDOM_STORY_DELETE({ id, appId }) {
    try {
      yield put({
        type: 'LOGS_ADD_LOGS_CONTENT',
        appId: appId,
        action: 'delete',
        where: rteAppId(appId, 'RANDOM_STORY'),
        typeContent: 'randomStory',
        content: id,
      })
      yield take('LOGS_ADD_LOGS_SUCCESS')
      yield put({ type: 'LOGS_ADD_LOGS_RESET_SUCCESS' })

      yield put({ type: 'RANDOM_STORY_DELETING' })
      yield call(rsFirebase.firestore.deleteDocument, `appdata/${appId}/randomStories/${id}`)
      yield put({ type: 'RANDOM_STORY_DELETE_FROM_LIST', id })
      yield put({ type: 'RANDOM_STORY_DELETE_SUCCESS', module })
    } catch (error) {
      yield put({ type: 'RANDOM_STORY_SAVE_ERROR' })
    }
  },
})
