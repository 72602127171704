import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation'
import {
  BasicCheckbox,
  BasicButton,
  BasicInput,
  BasicLabel,
  BasicLink,
  BasicRadioButtons,
  LayoutCard,
} from 'components'
import { scrollToError, validationsPatterns } from 'utils'
import uuidv4 from 'uuid/v4'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as RandomStoryModule } from 'store/randomStory'

import messages from '../messages'
import PageActions from '../PageActions'
import { StyledInput } from './styles'
import { trash } from 'react-icons-kit/feather/trash'
import { plus } from 'react-icons-kit/feather/plus'
import { Icon } from 'react-icons-kit'
import { useState } from 'react'

const RandomStoryEditForm = ({
  appId,
  cardId,
  isNew,
  data,
  RandomStoryModule,
  onCancel,
  onDelete,
  onSave,
  noDelete,
}) => {
  const [randomMode, setRandomMode] = useState(data && data.randomMode ? data.randomMode : true)
  const intl = useIntl()

  const [endStoryOption, setEndStoryOption] = useState(
    data && data.endStoryOption ? data.endStoryOption : 'restart'
  )
  const [endStoryOptionLinkDisabled, setEndStoryOptionLinkDisabled] = useState(
    data && data.endStoryOption ? data.endStoryOption === 'restart' : true
  )
  const [urls, setUrls] = useState(data && data.urls ? data.urls : ['', ''])

  const addInput = (e) => {
    e.preventDefault()
    const urlsCopy = [...urls]
    urlsCopy.push('')
    setUrls(urlsCopy)
  }

  const handleRemoveUrl = (e, i) => {
    e.preventDefault()
    const urlsCopy = [...urls]
    urlsCopy.splice(i, 1)
    if (urlsCopy.length <= 1) {
      urlsCopy.push('')
    }
    setUrls(urlsCopy)
  }

  const generateOptions = () => {
    const options = []
    for (let i = 2; i <= urls.length; i++) {
      options.push({ id: i, name: `${i}` })
    }
    return options
  }

  const handleEndStoryOptionChange = (e) => {
    const selectedOption = e.target.value

    setEndStoryOption(selectedOption)
    setEndStoryOptionLinkDisabled(selectedOption === 'restart')
  }

  const handleValidation = (value) => {
    const stories = RandomStoryModule.getRandomStoryByName(value)
    return stories.length <= 1
  }

  const handleOnSubmit = (e, errors, newRandomStory) => {
    e.preventDefault()
    if (errors.length) {
      scrollToError()
      return false
    }

    const id = (data && data.id) || uuidv4()
    const currentRandomStory = { ...data, ...newRandomStory, ...{ id } }
    RandomStoryModule.saveRandomStory(appId, cardId, currentRandomStory, onSave)
    setTimeout(() => RandomStoryModule.resetStatus(), 5000)
    return true
  }

  return (
    <AvForm onSubmit={handleOnSubmit} model={data || null}>
      <fieldset disabled={RandomStoryModule.saving}>
        <LayoutCard
          title={
            isNew ? intl.formatMessage(messages.newRandomStory) : intl.formatMessage(messages.editRandomStory)
          }
          headerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={RandomStoryModule.saving}
              isNew={isNew}
              noDelete={noDelete}
            />
          }
          footerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={RandomStoryModule.saving}
              isNew={isNew}
              noDelete={noDelete}
            />
          }
        >
          <BasicInput
            name="name"
            label={intl.formatMessage(messages.nameRandomStory)}
            helpMessage={intl.formatMessage(messages.nameHelpRandomStory)}
            value={data && data.name ? data.name : ''}
            error={intl.formatMessage(messages.nameErrorRandomStory)}
            required
            onChange={handleValidation}
            validation={{
              async: (value) => handleValidation(value),
            }}
          />

          <hr />

          <BasicInput
            name="beginLink"
            label={intl.formatMessage(messages.beginningLink)}
            helpMessage={intl.formatMessage(messages.beginningLinkHelp)}
            value={data && data.beginLink ? data.beginLink : ''}
            error={intl.formatMessage(messages.beginningLinkError)}
            validation={{ pattern: validationsPatterns.urlExtended }}
            link={data ? data.beginLink : ''}
          />

          <AvGroup>
            <div style={{ marginBottom: '5px' }}>
              <BasicLabel
                label={intl.formatMessage(messages.links)}
                helpMessage={intl.formatMessage(messages.linksHelp)}
                required
              />
            </div>

            {urls &&
              urls.map((url, i) => (
                <StyledInput key={`url-input-${i}`}>
                  <span style={{ whiteSpace: 'nowrap' }}>{i + 1}</span>

                  <AvInput
                    name={`urls.${i}`}
                    id={`urls.${i}`}
                    type="text"
                    value={url}
                    required={i !== 0}
                    validate={{ pattern: validationsPatterns.urlExtended }}
                  />
                  {url && (
                    <div style={{ position: 'absolute', right: '70px', width: '80px', marginTop: '50px' }}>
                      <BasicLink link={url} />
                    </div>
                  )}

                  <BasicButton
                    icon={<Icon icon={trash} />}
                    onClick={(e) => handleRemoveUrl(e, i)}
                    round
                    color="grey"
                    outlined
                    small
                  />
                </StyledInput>
              ))}

            <AvFeedback>{intl.formatMessage(messages.linksError)}</AvFeedback>

            <BasicButton
              color="green"
              medium
              label={intl.formatMessage(messages.addInput)}
              icon={<Icon icon={plus} />}
              onClick={addInput}
            />
          </AvGroup>

          <BasicCheckbox
            name="randomMode"
            label={intl.formatMessage(messages.randomMode)}
            helpMessage={intl.formatMessage(messages.randomModeHelp)}
            value={data && data.randomMode !== undefined ? data.randomMode : true}
            onChange={(e) => setRandomMode(e.target.checked)}
          />
          {randomMode && (
            <BasicInput
              type="select"
              label={intl.formatMessage(messages.limit)}
              name="limit"
              value={data && data.limit ? data.limit : urls.length}
            >
              {generateOptions().map((input, i) => (
                <option key={i} value={input.id}>
                  {input.name}
                </option>
              ))}
            </BasicInput>
          )}

          <BasicInput
            name="endLink"
            label={intl.formatMessage(messages.endLink)}
            helpMessage={intl.formatMessage(messages.endLinkHelp)}
            value={data && data.endLink ? data.endLink : ''}
            error={intl.formatMessage(messages.endLinkError)}
            validation={{ pattern: validationsPatterns.urlExtended }}
            link={data ? data.endLink : ''}
          />

          <hr />

          <BasicRadioButtons
            name="endStoryOption"
            label={intl.formatMessage(messages.endStory)}
            onChange={handleEndStoryOptionChange}
            value={endStoryOption}
            radioButtons={[
              { label: intl.formatMessage(messages.endStoryLabelOpt1), value: 'restart', disabled: false },
              {
                label: intl.formatMessage(messages.endStoryLabelOpt2),
                value: 'redirection',
                disabled: false,
              },
            ]}
          />

          <BasicInput
            type="text"
            name="endStoryOptionLink"
            id="endStoryOptionLink"
            value={data && data.endStoryOptionLink ? data.endStoryOptionLink : ''}
            error={intl.formatMessage(messages.endStoryError)}
            disabled={endStoryOptionLinkDisabled}
            required={!endStoryOptionLinkDisabled}
            link={data ? data.endStoryOptionLink : ''}
          />
        </LayoutCard>
      </fieldset>
    </AvForm>
  )
}

RandomStoryEditForm.defaultProps = {
  onCancel: () => {},
  onDelete: () => {},
  onSave: () => {},
}

RandomStoryEditForm.propTypes = {
  appId: PropTypes.string,
  cardId: PropTypes.string,
  isNew: PropTypes.bool,
  data: PropTypes.object,
  RandomStoryModule: PropTypes.object,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  noDelete: PropTypes.bool,
}

export default connectStore({ RandomStoryModule: RandomStoryModule })(RandomStoryEditForm)
