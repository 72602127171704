import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.RedirectOrderedForm'

export default defineMessages({
  addInput: { id: `${scope}.addInput` },
  days: { id: `${scope}.days` },
  hours: { id: `${scope}.hours` },
  info: { id: `${scope}.info` },
  links: { id: `${scope}.links` },
  linksError: { id: `${scope}.linksError` },
  linksHelp: { id: `${scope}.linksHelp` },
  minutes: { id: `${scope}.minutes` },
  notes: { id: `${scope}.notes` },
  notesHelp: { id: `${scope}.notesHelp` },
  params: { id: `${scope}.params` },
  repeat: { id: `${scope}.repeat` },
  show: { id: `${scope}.show` },
  time: { id: `${scope}.time` },
  timeError: { id: `${scope}.timeError` },
	redirection_ssr_event: { id: `${scope}.redirection_ssr_event` },
	redirection_ssr_eventHelp: { id: `${scope}.redirection_ssr_eventHelp` },
})
