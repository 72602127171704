import PropTypes from 'prop-types'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import messages from './messages'
import Item from './Item'
import { appIsSuspended } from '../../services/utils'

const StyledListGroupItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  ${({ noselect }) => noselect === 'true' && 'pointer-events: none;'}
`

const LayoutList = ({
  current,
  displayItem,
  horizontal,
  link,
  list = [],
  noSelect,
  onClick,
  props,
  tinny,
  currentUser,
}) => {
  const intl = useIntl()

  return (
    <ListGroup {...props} horizontal={horizontal}>
      {list && list.length > 0 ? (
        list.map(
          (item, index) =>
            item && (
              <Item
                active={(current && current === item.id) || false}
                displayItem={displayItem}
                item={item}
                key={index}
                link={!appIsSuspended(item, currentUser) && link ? `${link.replace(':id', item.id)}` : null}
                onClick={!appIsSuspended(item, currentUser) ? onClick : null}
                tinny={tinny}
                noSelect={noSelect}
              />
            )
        )
      ) : (
        <StyledListGroupItem>{intl.formatMessage(messages.empty)}</StyledListGroupItem>
      )}
    </ListGroup>
  )
}

LayoutList.propTypes = {
  current: PropTypes.any,
  displayItem: PropTypes.array,
  horizontal: PropTypes.any,
  link: PropTypes.any,
  list: PropTypes.array,
  noSelect: PropTypes.bool,
  onClick: PropTypes.func,
  props: PropTypes.any,
  tinny: PropTypes.any,
  currentUser: PropTypes.object,
}
export default LayoutList
