import {MSJ_ADMIN_LOCK, ROLES, ROLES_GLOBAL_ACCESS, ROLES_GLOBAL_ACTION} from "../utils/consts";
import {checkAccessDates} from "../utils/share";
import moment from 'moment-timezone'


export function readFileExtension(filename) {
  return filename.split('.').pop()
}
export function readFileName(filename) {
  const nameArray = filename.split('.')
  nameArray.pop()
  return nameArray.join('.')
}
export function splitFileName(filename) {
  return {
    name: readFileName(filename).replace(/ /g, '_'),
    ext: readFileExtension(filename),
  }
}
export function youtubeParser(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}
export function findCardLinkByTokenId(appCardLinks, tokenId) {
  const CardId = appCardLinks.filter((link, i) => {
    link.index = i
    return link.tokenId.toString() === tokenId.toString()
  })
  return CardId[0]
}

export function groupArrayBy(array, prop) {
  return array.reduce((groups, item) => {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

export function chunkArray(arr, len) {
  const chunks = []
  let i = 0
  const n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

export const readImgSizeByUrl = (imageData) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.src = imageData
  })

export function checkGlobalAccess(appId, superadmin, roles, destination) {
  const appRole = roles.find(role => role.appId === appId);
  const role = appRole ? appRole.roles[0].role : null;

  const checkRole = superadmin ? 'superadmin' : role;
  return ROLES.includes(checkRole) && ROLES_GLOBAL_ACCESS[destination].indexOf(checkRole) > -1;
}

export function checkGlobalAccessSuperowner(appId, roles, destination) {
	const appRole = roles.find(role => role.appId === appId);
	const role = appRole ? appRole.roles[0].role : null;

	return role === 'superowner' && ROLES.includes(role) && ROLES_GLOBAL_ACCESS[destination].indexOf(role) > -1;
}

export function checkFoldersAccess(appId, user, folderId){
  if(user.superadmin) {
    return true;
  }
  const userApp = user.apps.find(app => app.appId === appId);
  if(userApp) {
    const role = userApp.roles[0];
    if(role.typeAccess === 'allApp') {
      return true;
    }
    return role.access && role.access.folders && role.access.folders.some(folderAccess => {
      return folderAccess.folderId === folderId && checkAccessDates(folderAccess);
    });
  }
  return false;
}

export function checkCardsAccess(appId, user, tokenId, cardId) {

  if(user.superadmin) {
    return true;
  }
  const userApp = user.apps.find(app => app.appId === appId);
  const typeCard = tokenId !== 0 ? 'linked' : 'unlinked';

  if(userApp) {
    const role = userApp.roles[0];
    if(role.typeAccess === 'allApp') {
      return true;
    }
    return role.access && role.access[typeCard] && role.access[typeCard].some(cardAccess => {
      return cardAccess.cardId === cardId && checkAccessDates(cardAccess);
    });
  }

  return false;
}

export function checkActionsSuperadmin(user) {
  if(user.superadmin) {
    return true;
  }

  return user.apps.some(app =>
    app.roles.some(role => role.role === 'superowner')
  );
}

export function checkActionsAccess(appId, user, section, action){
  if(user.superadmin) {
    return true;
  }
  const userApp = user.apps.find(app => app.appId === appId);

  if (userApp) {
    const role = userApp.roles[0];
    const actions = ROLES_GLOBAL_ACTION[role.role][section];
    return actions.includes(action);
  }

  return true;
}

export function checkMsjAdminLock(currentUser, user){
  return (currentUser.email !== MSJ_ADMIN_LOCK && user.email !== MSJ_ADMIN_LOCK) || currentUser.email === MSJ_ADMIN_LOCK;
}

export function appIsSuspended(app, profile) {
  if(profile) {
    if (profile.superadmin) {
      return false;
    }
    const appProfile = profile.apps.find(appObj => appObj.appId === app.id);

    if (appProfile && appProfile.roles.length === 1) {
      const { role } = appProfile.roles[0];
      if (role === "superowner") {
        return false;
      }
    }

    if(app.accessSuspended) {
      const start = app.accessSuspended.suspensionStart ? moment(app.accessSuspended.suspensionStart, 'DD/MM/YYYY').startOf('day') : null;
      const end = app.accessSuspended.suspensionEnd ? moment(app.accessSuspended.suspensionEnd, 'DD/MM/YYYY').endOf('day') : null;

      return (app.accessSuspended.status && !start && !end) ||
        (app.accessSuspended.status && start && !end && moment().isAfter(start)) ||
        (app.accessSuspended.status && !start && end && moment().isBefore(end)) ||
        (start && end && moment().isBetween(start, end, null, '[]'));
    }
  }
  return false;
}
